import React from "react"
import { Container } from "react-bootstrap"
import Feature from "../components/Feature"
import Footer from "../components/footer"
import Layout from "../components/layout"
import LogoCloud from "../components/LogoClouds"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import NewHero from "../components/hero/newHero";

const About = ({ location }) => {
  return (
    <Layout>
      <Seo title="Team Coaches" />
      <NavBar location={location} />
      <Container>
        <NewHero
          title="Community Software for innovation programs"
          description="Team Coaches helps accelerators, incubators, and enterprises to manage their innovation programs in the most efficient way."
        />
        <LogoCloud />
        <Feature />
        <Footer />
      </Container>
    </Layout>
  )
}

export default About
