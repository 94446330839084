import React from "react"
import { Container, Col, Row, Image, Button } from "react-bootstrap"
// Images
import Hat from "../../static/images/feature-hat.png"
import HappyGuy from "../../static/images/feature-happy-guy.png"
import Chess from "../../static/images/feature-chess.png"
import Cofee from "../../static/images/feature-coffe.png"
import Frame from "../../static/images/feature-frame.svg"
import PeopleGroup from "../../static/images/feature-peoplegroup.svg"
const Feature = () => {
  /* Work on Responsiveness */
  // ? TODO Simplify Col of Feature-images?
  // @Jesse Code that i need help making fully responsive is Row first and Row Second, specifically the images
  return (
    <Row fluid="md" className="feature-container m-0">
      <Col fluid className="feature-images d-none d-md-inline">
        <Row fluid className="first">
          <Col fluid>
            <Image fluid rounded src={Hat} />
            <Image fluid rounded src={HappyGuy} />
          </Col>
        </Row>
        <Row fluid className="second">
          <Col fluid>
            <Image fluid rounded src={Chess} />
            <Image fluid rounded src={Cofee} />
          </Col>
        </Row>
      </Col>
      <Col fluid className="feature-text">
        <h3 className="span-title">About Us</h3>
        <span className="span-text">
          We have lived this and we know what is important. We listen and we
          improve the same way we offer full support on the implementation. We
          are building something that will make startups worth creating.
        </span>
        <Container className="feature-detail">
          <Image fluid rounded width="48" src={Frame} />
          <Container className="text-col">
            <h6>Headline</h6>
            <span>One line description</span>
          </Container>
        </Container>
        <Container className="feature-detail">
          <Image fluid rounded width="48" src={PeopleGroup} />
          <Container className="text-col">
            <h6>Headline</h6>
            <span>One line description</span>
          </Container>
        </Container>
        <Button>Let's Talk</Button>
      </Col>
    </Row>
  )
}

export default Feature
